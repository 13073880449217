import React, { useState } from 'react';
import {
  EventContainer,
  EventContent,
  MainEventContent,
  EventContentImage,
  EventContainerImage,
  EventContentCredit,
  ExternalButton,
  PaginationBackground,
  // Pagination
  PaginationContainer,
  PaginationContent,
  PaginationDate,
  PaginationTitle,
  PaginationImage,
} from './styles';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Header from 'components/Layouts/Generic/Header';
import Body from 'components/Layouts/Generic/Body';
import Breadcrumb from 'components/Breadcrumb';
import Tabs from 'components/Tabs';
import PFEQ from './pfeq'
import $ from 'jquery';
const tabsArray = [
  {
    title: 'Contexte',
    hash: 'contexte',
  },
  {
    title: 'Document(s) visuel(s)',
    hash: 'documents-visuels',
  },
  // {
  //   title: 'Sources',
  //   hash: 'sources',
  // },
  {
    title: 'Activité(s) éducative(s)',
    hash: 'activites-educatives',
  },
];

const Event = ({ event, location, events, terms }) => {
  const [tab, setTab] = useState(tabsArray[0]);
  const nextEvent = events.find((evt) => evt.EventId === event.EventId + 1);
  const previousEvent = events.find((evt) => evt.EventId === event.EventId - 1);

  const imagePath = event?.FeatureImagePath?.replace('220', '900');

  return (
    <EventContainer>
      <Breadcrumb location={location} />
      <Header title={event.Title} overline={event?.StartDisplay} />
      <Tabs
        items={tabsArray}
        location={location}
        state={tab}
        setState={setTab}
      />
      <MainEventContent>
        <SwitchTransition>
          <CSSTransition
            key={tab?.hash}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            classNames='main-body-animate'
          >
            {tab?.hash === 'contexte' ? (
              <EventContent>
                <Body html={event?.Body} />
              </EventContent>
            ) : tab?.hash === 'documents-visuels' ? (
              <EventContent>
                <EventContainerImage>
                  {event?.BAnQ_ThumbnailNoticePath && (
                    <a target='_blank' href={event.BAnQ_ThumbnailNoticePath}>
                      <svg
                        width='48'
                        height='48'
                        viewBox='0 0 48 48'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect width='48' height='48' fill='#7E8BC3' />
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M31.415 18.41L26.915 22.91L25.505 21.5L30.005 17H26.415V15H33.415V22H31.415V18.41ZM22.415 31V33H15.415V26H17.415V29.59L21.915 25.09L23.325 26.5L18.825 31H22.415Z'
                          fill='black'
                        />
                      </svg>
                    </a>
                  )}
                  {imagePath && <EventContentImage src={imagePath} />}
                </EventContainerImage>
                {event?.ImageCredit && (
                  <EventContentCredit
                    dangerouslySetInnerHTML={{ __html: event.ImageCredit }}
                  />
                )}
                <ExternalButton
                  href={event.BAnQ_ThumbnailNoticePath}
                  target='_blank'
                >
                  <span>Consulter sur BAnQ numérique</span>
                  <svg
                    width='20'
                    height='21'
                    viewBox='0 0 20 21'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M4.16667 4.66667V16.3333H15.8333V10.5H17.5V16.3333C17.5 17.2538 16.7538 18 15.8333 18H4.16667C3.24619 18 2.5 17.2538 2.5 16.3333V4.66667C2.5 3.74619 3.24619 3 4.16667 3H10V4.66667H4.16667ZM11.6667 4.66667V3H17.5V8.83333H15.8333V5.84167L7.64166 14.0333L6.46667 12.8583L14.6583 4.66667H11.6667Z'
                      fill='black'
                    />
                  </svg>
                </ExternalButton>
              </EventContent>
            ) : tab?.hash === 'sources' ? (
              <Body html={`<p>sources</p>`} />
            ) : tab?.hash === 'activites-educatives' ? <PFEQ terms={terms} event={event} /> : (
              <></>
            )}
          </CSSTransition>
        </SwitchTransition>
      </MainEventContent>
      <PaginationBackground >
        <PaginationContainer>
          {previousEvent && (
            <PaginationContent to={`/evenement/${previousEvent.EventId}`}>
              <svg
                style={{ marginRight: '60px' }}
                width='68'
                height='39'
                viewBox='0 0 68 39'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M66.6579 19.0376H2.3421'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='square'
                />
                <path
                  d='M20.7316 1.45661L1.48667 19.5L20.7316 37.5434'
                  stroke='black'
                  strokeWidth='2'
                />
              </svg>
              <div
                style={{
                  marginRight: '40px',
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <div style={{ textAlign: 'right' }}>
                  <PaginationDate elementTheme='caption'>
                    {previousEvent.StartDisplay}
                  </PaginationDate>
                  <PaginationTitle>{previousEvent.Title}</PaginationTitle>
                </div>
                {previousEvent?.ThumbnailPath && <PaginationImage
                  style={{ marginLeft: '20px' }}
                  src={previousEvent.ThumbnailPath}
                />}
              </div>
            </PaginationContent>
          )}
          {nextEvent && (
            <PaginationContent to={`/evenement/${nextEvent.EventId}`}>
              <div
                style={{
                  marginLeft: '40px',
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <PaginationImage
                  style={{ marginRight: '20px' }}
                  src={nextEvent.ThumbnailPath}
                />
                <div>
                  <PaginationDate elementTheme='caption'>
                    {nextEvent.StartDisplay}
                  </PaginationDate>
                  <PaginationTitle>{nextEvent.Title}</PaginationTitle>
                </div>
              </div>
              <svg
                style={{ transform: 'rotate(180deg)', marginLeft: '60px' }}
                width='68'
                height='39'
                viewBox='0 0 68 39'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M66.6579 19.0376H2.3421'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='square'
                />
                <path
                  d='M20.7316 1.45661L1.48667 19.5L20.7316 37.5434'
                  stroke='black'
                  strokeWidth='2'
                />
              </svg>
            </PaginationContent>
          )}
        </PaginationContainer>
      </PaginationBackground>
    </EventContainer>
  );
};

export default Event;
