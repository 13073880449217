import React from 'react';
import { graphql } from 'gatsby';
import ResolutionChecker from 'components/ResolutionChecker';
import Event from 'components/Layouts/Event';

const EventsPageTemplate = (props) => {
  const {
    allTimelineEvents: {
      edges: [{ node }],
    },
    allTimelineTerms: {
      edges: [{ node: terms }],
    },
  } = props.data;

  const historicalEvent = node.Events.find(
    (event) =>
      event.EventId === props.pageContext.eventId &&
      event.Title === props.pageContext.title
  );

  return (
    <ResolutionChecker>
      <Event
        terms={terms}
        event={historicalEvent}
        events={node.Events}
        location={props?.location}
      />
    </ResolutionChecker>
  );
};

export default EventsPageTemplate;

export const query = graphql`
  query {
    allTimelineEvents {
      edges {
        node {
          Events {
            BAnQ_LastUpdateDate
            BAnQ_ThumbnailNoticePath
            Body
            FeatureImagePath
            EventId
            ImageCredit
            LinkTarget
            JulianDayStart
            LinkText
            Priority
            StartDisplay
            ThumbnailPath
            StartDisplayYear
            Title
            Year
            DescriptionFirme
            activites {
              IdActCompetence
              LibelleCompetence
              TitreActivite
              UrlActivite
            }
            competences {
              id
              terme
            }
            date_debut
            date_debut_texte
            disciplines {
              id
              terme
            }
            domaines {
              id
              terme
            }
            municipalites {
              id
              terme
            }
            nations {
              id
              terme
            }
            personnages {
              id
              terme
            }
            priorite
            regions {
              id
              terme
            }
            thematiques {
              id
              terme
            }
          }
          Title
          Body
        }
      }
    }
    allTimelineTerms {
      edges {
        node {
          municipalites {
            evenements
            id
            terme
          }
          mots_cles {
            evenements
            id
            terme
          }
          regions {
            evenements
            id
            terme
          }
          thematiques {
            evenements
            id
            terme
          }
          nations {
            evenements
            id
            terme
          }
          domaines {
            id
            terme
            evenements
          }
          disciplines {
            id
            terme
            evenements
          }
          competences {
            id
            terme
            evenements
          }
          DDC {
            DOMAINES {
              id
              terme
              DISCIPLINES {
                id
                terme
                COMPETENCES {
                  id
                  terme
                }
              }
            }
          }
        }
      }
    }
  }
`;
